var dialogClass = '.my-dialog';

export function NewModal(content) {
    this.content = content;
    this.modal = false;
    this.modalBody = false;
    this.error = false;
}

NewModal.prototype.showModal = function () {
    var $body = $('body');
    $body.append(this.content);
    this.modal = $('#' + $body.find(dialogClass).last().attr('id'));
    this.modalBody = this.modal.find('.modal-body');
    this.error = this.modal.find('.error');
    this.modal.modal('show')
        .on('hidden.bs.modal', function () {
            $(this).remove();
        })
        .on('hide.bs.modal', function () {
            $(this).remove();
        });
    return this.modal;
};

$(function() {
    var tooltips = $('[data-toggle="tooltip"]');
    if (tooltips.length > 0) {
        $('[data-toggle="tooltip"]').tooltip();
    }
});

export function sendRequest(type, url, data, callback) {
    return $.ajax({
        type: type,
        url: url,
        dataType: 'json',
        data: data,
        success: function (response, textStatus, jqXHR) {
            if (callback) {
                callback.call(self, response);
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            if (callback) {
                callback.call(self, {message: 'Произошла ошибка на стороне сервера'});
            } else {
                showError('Произошла ошибка на стороне сервера');
            }
        },
        beforeSend: function () {
            //делаем что то перед отправкой
        },
        complete: function () {
            //делаем что то после выполнения
        }
    });
}

var alertContainer = $('#main-alert-container');
export function newShowInfo(text, success) {
    var div = document.createElement("Div");
    div.className = 'alert my-alert ' + (success ? 'alert-success' : 'alert-danger');

    if (div) {
        var span = document.createElement("Span");
        span.className = 'alert-close-btn';
        span.addEventListener('click', function () {
            this.parentElement.remove();
        });
        span.innerHTML = '&times;';
        div.appendChild(span);
    }
    if (div) {
        var span = document.createElement("Span");
        span.innerHTML = text ? text : (success ? 'Успешно': 'Произошла ошибка на стороне сервера');
        div.appendChild(span);

        alertContainer.append(div);
        $.when($(div).fadeIn().delay(8000).fadeOut()).done(function () {
            $(div).remove();
        });
    }
}

export const serialize = function (obj, prefix) {
    var str = [],
        p;
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p];
            str.push((v !== null && typeof v === 'object') ?
                serialize(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
    }
    return str.join('&');
};
