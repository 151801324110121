var btnUp = $('#btn-up');

btnUp.on('click', () => {
    $('html, body').animate({scrollTop: 0}, 500);
    return false;
});

$(document).on('scroll', () => {
    var scrolled = window.pageYOffset || document.documentElement.scrollTop;
    var btnUpIsVisible = btnUp.is(':visible');
    if (scrolled > 500) {
        if (!btnUpIsVisible) {
            btnUp.show();
        }
    } else {
        if (btnUpIsVisible) {
            btnUp.hide();
        }
    }
});